import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgramById, updateProgram, updateProgramStatus } from '../../../../services/program.service';

const DetailsAppelACandidature = () => {
    const [programById, setProgramById] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [updatedProgram, setUpdatedProgram] = useState({});
    const [originalStatus, setOriginalStatus] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    const imageUrl = programById?.image?.url?.startsWith("http") ? programById?.image?.url : `https://${programById?.image?.url}`;

    useEffect(() => {
        const getProgram = async () => {
            try {
                const data = await getProgramById(id);
                setProgramById(data);
                setUpdatedProgram(data);
                setOriginalStatus(data?.status);
            } catch (error) {
                console.error('Error fetching program:', error);
            }
        };

        if (id) {
            getProgram();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedProgram((prevProgram) => ({
            ...prevProgram,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            setImagePreviewUrl(null);
        }
    };

    const handleUpdateProgram = async () => {
        try {
            const formData = new FormData();
            formData.append('title', updatedProgram.title);
            formData.append('expiresIn', updatedProgram.expiresIn);
            formData.append('description', updatedProgram.description);
            formData.append('exigence', updatedProgram.exigence);
            formData.append('status', updatedProgram.status);
            formData.append('category', updatedProgram.category);

            if (imageFile) {
                formData.append('image', imageFile);
            }

            if (updatedProgram.status !== originalStatus) {
                await updateProgramStatus(id, updatedProgram.status);
            }

            await updateProgram(id, formData);
            toast.success("L'appel à candidature mis à jour avec succès!", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
            });

            setIsEditing(false);
            const updatedData = await getProgramById(id);
            setProgramById(updatedData);
            setImagePreviewUrl(null);

            setTimeout(() => {
                navigate("/unite_de_gestion-dashboard/Appels-à-candidatures");
            }, 2000);
        } catch (error) {
            console.error("Error updating program:", error);
            toast.error("Erreur lors de la mise à jour de l'appel à candidature.");
        }
    };

    if (!programById) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
            <div className='flex justify-between'>
                <h1 className="text-3xl font-bold mb-2">Détails appel à candidatures</h1>
                <div>
                    {isEditing ? (
                        <button
                            onClick={handleUpdateProgram}
                            variant="contained"
                            className='bg-[#1976d2] text-white px-4 py-2 mb-2 rounded-lg hover:bg-[#1565c0]'
                        >
                            Sauvegarder
                        </button>
                    ) : (
                        <button
                            onClick={() => setIsEditing(true)}
                            variant="contained"
                            color="primary"
                            className='bg-gray-200 text-gray-700 px-4 py-2 mb-2 rounded-lg hover:bg-gray-300'>
                            Modifier
                        </button>
                    )}
                </div>
            </div>
            <div className="border rounded-lg p-4 mt-3 bg-white ">
                <div className="space-y-3">
                    <div className="mt-4">
                        <div className="flex items-center p-3">
                            <span className="w-[30%]">
                                <strong>Titre :</strong>
                            </span>
                            {isEditing ? (
                                <TextField
                                    name="title"
                                    value={updatedProgram.title || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            ) : (
                                programById.title
                            )}
                        </div>

                        <div className="flex  items-center p-3">
                            <span className="w-[30%]">
                                <strong>Date limite de <br/> soumission :</strong>
                            </span>
                            {/* <div className='w-[100%]'> */}
                                {isEditing ? (
                                    <TextField
                                        name="expiresIn"
                                        type="date"
                                        value={updatedProgram.expiresIn ? new Date(updatedProgram.expiresIn).toISOString().slice(0, 10) : ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                ) : (
                                    new Date(programById.expiresIn).toLocaleDateString('fr-FR')
                                )}
                            {/* </div> */}
                        </div>

                        <div className="flex  items-center p-3">
                            <span className="w-[30%]">
                                <strong>Description :</strong>
                            </span>
                            {/* <div className="w-[100%]"> */}
                                {isEditing ? (
                                    <TextField
                                        name="description"
                                        value={updatedProgram.description || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                ) : (
                                    programById.description
                                )}

                            {/* </div> */}

                        </div>

                        <div className="flex  items-center p-3">
                            <span className="w-[30%]">
                                <strong>Exigences :</strong>
                            </span>
                            {/* <div className="w-[100%]"> */}
                                {isEditing ? (
                                    <TextField
                                        name="exigence"
                                        value={updatedProgram.exigence || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                ) : (
                                    programById.exigence
                                )}

                            {/* </div> */}
                        </div>

                        <div className="flex items-center p-3">
                            <span className="w-[30%]">
                                <strong>Status :</strong>
                            </span>
                            {isEditing ? (
                                <FormControl fullWidth>
                                    <Select
                                        name="status"
                                        value={updatedProgram.status || ''}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="Publié">publié</MenuItem>
                                        <MenuItem value="Brouillon">brouillon</MenuItem>
                                        <MenuItem value="Fermé">fermé</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                programById.status
                            )}
                        </div>

                        <div className="flex items-center p-3">
                            <span className="w-[30%]">
                                <strong>Catégorie :</strong>
                            </span>
                            {isEditing ? (
                                <FormControl fullWidth>
                                    <Select
                                        name="category"
                                        value={updatedProgram.category || ''}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="technology">Technologie</MenuItem>
                                        <MenuItem value="business">Business</MenuItem>
                                        <MenuItem value="education">Éducation</MenuItem>
                                        <MenuItem value="health">Santé</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                programById.category
                            )}
                        </div>

                        {programById.image && programById.image.url && !imagePreviewUrl && (
                            <div className="flex  items-center p-3">
                                <span className="w-[30%]">
                                    <strong>Image :</strong>
                                </span>
                                <img
                                    src={imageUrl}
                                    alt={programById.image.name || 'Program Image'}
                                    className="max-w-xs rounded-lg shadow-lg"
                                />
                            </div>
                        )}

                        {isEditing && (
                            <div className="flex  items-center p-3">
                                <span className="w-[30%]">
                                    <strong>Changer l'image :</strong>
                                </span>
                                <input type="file" onChange={handleImageChange} />
                            </div>
                        )}

                        {imagePreviewUrl && (
                            <div className="flex  items-center p-3">
                                <span className="w-[30%]">
                                    <strong>Image Preview:</strong>
                                </span>
                                <img
                                    src={imagePreviewUrl}
                                    alt="Preview"
                                    className="max-w-xs rounded-lg shadow-lg"
                                />
                            </div>
                        )}
                    </div>
                    <div className="mt-4 flex items-center gap-4">
                        <div className="mt-6 flex justify-between">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
                            >
                                Retour à la page d'accueil
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default DetailsAppelACandidature;
