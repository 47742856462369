import axios from "axios";
import axiosInstance from "../helpers/axiosIntercepter";

export const loginService = async (values) => {
  try {
    const response = await axiosInstance.post(
      "/authentication/login",
      values
    );
    return response;
  } catch (error) {
    console.error("Error fetching active programs:", error);
    throw error;
  }
};

export const forgetPasswordService = async (values) => {
  try {
    const response = await axiosInstance.post(
      "/authentication/forgot-password",
      values
    );
    return response;
  } catch (error) {
    console.error("Error fogot password :", error);
    throw error;
  }
};


export const updateProfileService = async (userId, values) => {
  try {
    const response = await axiosInstance.put(
      `/authentication/update-profile/${userId}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

export const refreshTokenService = async (refreshToken) => {
  try {
    const response = await axiosInstance.post("/authentication/refresh-token", {
      refreshToken,
    });
    console.log("refreshTokenService", response);
    return response;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};
