

import axiosInstance from "../helpers/axiosIntercepter";


export const verifyCaptcha = async (recaptchaToken) => {
  try {
    const payload = { recaptchaToken };

    const response = await axiosInstance.post('/captcha', payload);
    return response.data;
  } catch (error) {
    console.error("Failed to validate captcha:", error);
    throw error;
  }
};
