import React, { useContext, useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, Serializer } from "survey-core";
import "survey-core/i18n/french";
import { sendSurveyResponse } from "../services/surveyResponse.service";
import { getProgramToFill } from "../services/program.service";
import { uploadFile } from "../services/uploadFile.service";
import Stepper from "./Stepper";
import "survey-core/defaultV2.min.css";
import { PlainLight } from "survey-core/themes";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import LaureatGetSurveyForm from "../pages/Laureat-Dashboard/LaureatGetSurveyForm";
import { CircularProgress } from "@mui/material";

const LaureatGetProgramSurvey = () => {
  Serializer.addProperty("itemvalue", { name: "score:number" });

  const [surveyModel, setSurveyModel] = useState(null);
  console.log( "surveyModel", surveyModel);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorData, setErrorData] = useState(null);
  console.log("errorData", errorData);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const programId = localStorage.getItem("programId");
    const token = localStorage.getItem("access-token");

    if (!programId || !userId) {
      // toast.warn("Program or user not selected, redirecting...", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   theme: "colored",
      //   transition: Bounce,
      // });
      // setTimeout(() => {
      //   navigate("/laureat-dashboard");
      // }, 2000); // 2 second delay for redirection
      console.log("heklloo errorr");
      return;
    }

    const fetchProgramToFill = async () => {
      try {
        const program = await getProgramToFill(programId, userId);

        const formFields =
          program?.form?.fields || program?.program?.form?.fields;

        if (formFields) {
          const parsedSurvey = JSON.parse(formFields);
          // setSurveyModel(parsedSurvey);

          const stepsTitles = parsedSurvey.pages.map((page) => ({
            title: page.title,
            name: page.name,
          }));

          stepsTitles.push({ title: "", name: "" });


          const survey = new Model(parsedSurvey);
          survey.applyTheme(PlainLight);
          survey.showPreviewBeforeComplete = "showAnsweredQuestions";
          survey.locale = "fr";

          // Fetch old responses if status is Brouillon
          if (program?.surveyResponse?.status === "Brouillon") {
            const oldResponses = program.surveyResponse.responses;
            if (oldResponses) {
              survey.data = oldResponses;
            }
          }
          // Handle file uploads
          // survey.onUploadFiles.add(async (sender, options) => {
          //   try {
          //     const uploadedFiles = await Promise.all(
          //       options.files.map(async (file) => {
          //         const uploadResult = await uploadFile(file);
          //         return uploadResult.url;
          //       })
          //     );
          //     options.callback("success", uploadedFiles);
          //   } catch (error) {
          //     options.callback("error");
          //   }
          // });


          
          survey?.getAllQuestions()?.forEach((question) => {
            if (question?.getType() === "file") {
              question.storeDataAsText = false;
              question.allowImagesPreview = true;
              question.waitForUpload = true;
              question.allowMultiple = true;
            }
          });

          // Handle file uploads
          survey.onUploadFiles?.add(async (sender, options) => {
            try {
              const uploadedUrls = [];

              // Loop through the files and upload them one by one
              for (const file of options.files) {
                const urls = await uploadFile(
                  file,
                  "laureat/" + localStorage.getItem("programId") + "/" + userId,
                  18000,
                  "public"
                );

                uploadedUrls.push(
                  Object.assign({}, file, {
                    name: urls[0].name,
                    fullUrl: urls[0].url,
                    type: file.type,
                    size: file.size,
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    webkitRelativePath: file.webkitRelativePath,
                  })
                );
              }

              // Assign uploaded URLs to options.files for SurveyJS to handle
              options.files = uploadedUrls;

              // Callback to notify SurveyJS about successful upload
              options.callback(
                options.files.map((file) => ({
                  file: file,
                  content: file.fullUrl, 
                }))
              );
            } catch (error) {
              console.error("File upload error:", error);
              options.callback("error");
            }
          });

          // Handle step changes
          survey.onCurrentPageChanged?.add((sender) => {
            const newIndex = stepsTitles.findIndex(
              (step) => step.name === sender.currentPage.name
            );
            setIsFinalStep(newIndex + 1 === stepsTitles.length);
            setCurrentStep(newIndex);
          });

          // Save responses locally
          survey.onValueChanged?.add(async (sender, options) => {
            const surveyData = survey.getData(); //currentPage.getValue();
            const programId = parseInt(localStorage.getItem("programId"));

            for (const key in surveyData) {
              if (surveyData.hasOwnProperty(key)) {
                const question = survey.getQuestionByValueName(key);

                if (question && question.getType() === "file") {
                  const files = surveyData[key];

                  const uploadedUrls = [];

                  for (const file of files) {
                    try {
                      const urls = await uploadFile(
                        file,
                        "laureat/" + programId,
                        18000,
                        "private"
                      );

                      uploadedUrls.push({
                        name: file.name,
                        content: urls[0], 
                        type: file.type,
                        size: file.size,
                      });
                    } catch (error) {
                      console.error("Error uploading file:", error);
                    }
                  }

                  surveyData[key] = uploadedUrls;
                }
              }
            }

            localStorage.setItem("savedResponses", JSON.stringify(surveyData));
            console.log("Saved current page data with file URLs:", surveyData);
          });


          const savedResponses = JSON.parse(localStorage.getItem("savedResponses"));

          if (savedResponses) {
            survey.data = savedResponses;
            console.log("Restored saved responses:", savedResponses);
            // Find the step where we left off (if responses exist)
            const lastPageIndex = stepsTitles.findIndex(step => step.name === Object.keys(savedResponses)[0]);
            setCurrentStep(lastPageIndex !== -1 ? lastPageIndex : 0);
          }
          // Add enregistrer button for draft saving
          survey.addNavigationItem({
            id: "survey_save_current_page",
            title: "Enregistrer",
            action: async () => {
              const surveyData = survey.data;
              const programId = parseInt(localStorage.getItem("programId"));
              const token = localStorage.getItem("access-token");

              for (const key in surveyData) {
                if (surveyData.hasOwnProperty(key)) {
                  const question = survey.getQuestionByValueName(key);

                  if (question && question.getType() === "file") {
                    const files = surveyData[key];

                    // Replace file content with MinIO URLs
                    const uploadedUrls = [];

                    for (const file of files) {
                      try {
                        const urls = await uploadFile(
                          file,
                          "laureat/" + localStorage.getItem("programId"),
                          18000,
                          "private"
                        );

                        // Replace file content with URL
                        uploadedUrls.push({
                          name: file.name,
                          content: urls[0], // Use MinIO URL
                          type: file.type,
                          size: file.size,
                        });
                      } catch (error) {
                        console.error("Error uploading file:", error);
                      }
                    }

                    surveyData[key] = uploadedUrls;
                  }
                }
              }

              const responseData = {
                programId: programId,
                responses: surveyData,
                action: "enregistrer",
                user_id: userId,
              };

              try {
                await sendSurveyResponse(token, responseData);
                toast.success(
                  "Vos réponses ont été enregistrées avec succès en tant que brouillon!",
                  {
                    position: "bottom-right",
                    autoClose: 2000,
                    theme: "colored",
                    transition: Bounce,
                  }
                );

                setTimeout(() => {
                  navigate("/laureat-dashboard");
                }, 2000);
              } catch (error) {
                console.error("Error saving the draft:", error);
                toast.error("Erreur lors de l'enregistrement du brouillon. Veuillez réessayer.", {
                  position: "bottom-right",
                  autoClose: 2000,
                  theme: "colored",
                  transition: Bounce,
                });
              }
            },
          });


          // Scoring logic
          function calculateMaxScore(questions) {
            let maxScore = 0;
         
            questions.forEach((question) => {
              // For radio buttons or checkboxes (choices)
              if (question.choices) {
                if (question.getType() === "checkbox" || question.getType() === "tagbox" ) {
                  // For checkboxes, sum the scores of all choices
                  const totalCheckboxScore = question.choices.reduce(
                    (sum, choice) => sum + (choice.score || 0),
                    0
                  );
                  console.log("total check box :" , totalCheckboxScore, question.getType())
                  maxScore += totalCheckboxScore;
                } else {
                  // For radio buttons, get only the highest score
                  const maxValue = Math.max.apply(
                    Math,
                    question.choices.map((choice) => choice.score || 0)
                  );
                  console.log("maxvalue in checkbox", maxValue, question.name, question.getType())
                  maxScore += maxValue;
                }
              }
         
              // For rating scale questions (rateValues)
              if (question.rateValues) {
                const maxValue = Math.max.apply(
                  Math,
                  question.rateValues.map((rate) => rate.score || 0)
                );
                console.log(maxValue, "rating max value goes here")
                maxScore += maxValue;
              }
         
              // For matrix questions
              if (question.getType() === "matrix") {
                const maxMatrixValue =
                  Math.max.apply(
                    Math,
                    question.columns.map((column) => column.score || 0)
                  ) * question.rows.length;
                maxScore += maxMatrixValue;
              }
            });
         
            return maxScore;
          }
          function calculateTotalScore(data) {
            let totalScore = 0;
         
            data.forEach((item) => {
              const question = survey.getQuestionByValueName(item.name);
              const qValue = item.value;
         
              // For questions with choices (radio buttons or checkboxes)
              if (question?.choices) {
                if (Array.isArray(qValue)) {
                  // Checkbox (multiple selections)
                  qValue.forEach((selectedValue) => {
                    const selectedChoice = question.choices.find(
                      (choice) => choice.value === selectedValue
                    );
                    if (selectedChoice) {
                      console.log(selectedChoice, "total score multisemected choices")
                      totalScore += selectedChoice.score || 0;
                    }
                  });
                } else {
                  // Radio button (single selection)
                  const selectedChoice = question.choices.find(
                    (choice) => choice.value === qValue
                  );
                  if (selectedChoice) {
                    totalScore += selectedChoice.score || 0;
                  }
                }
              }
         
              // For rating scale questions
              if (question?.rateValues) {
                const selectedRate = question.rateValues.find(
                  (rate) => rate.value === qValue
 
                );
                if (selectedRate) {
                  console.log(selectedRate," selected rate ")
                  totalScore += selectedRate.score || 0;
                }
              }
         
              // For matrix questions
              if (question.getType() === "matrix" && item.data) {
                item.data.forEach((dataItem) => {
                  if (dataItem?.value && dataItem.score) {
                    totalScore += dataItem.score;
                  }
                });
              }
            });
         
            return totalScore;
          }

          // Handling completion
          survey.onCompleting?.add((sender) => {
            console.log( "senderrrrrrrrrrrrrrrrr", sender?.data);
            return;
            const maxScore = calculateMaxScore(sender.getAllQuestions());
            const plainData = sender.getPlainData({
              calculations: [{ propertyName: "score" }],
            });
            const totalScore = calculateTotalScore(plainData);
            sender.setValue("maxScore", maxScore);
            sender.setValue("totalScore", totalScore);
          });

          // Handle survey completion
          survey.onComplete?.add(async (sender) => {
            // console.log( "senderrrrrrrrrrrr", sender);
            // return;
            const totalScore = sender.getValue("totalScore"); 
            const maxScore = sender.getValue("maxScore");
            const surveyData = sender.data;
            const programId = parseInt(localStorage.getItem("programId"));
            const token = localStorage.getItem("access-token");

            for (const key in surveyData) {
              if (surveyData.hasOwnProperty(key)) {
                const question = survey.getQuestionByValueName(key);

                if (question && question.getType() === "file") {
                  const files = surveyData[key];

                  const uploadedUrls = [];

                  for (const file of files) {
                    try {
                      const urls = await uploadFile(
                        file,
                        "laureat/" + localStorage.getItem("programId"),
                        18000,
                        "private"
                      );

                      uploadedUrls.push({
                        name: file.name,
                        content: urls[0],
                        type: file.type,
                        size: file.size,
                      });
                    } catch (error) {
                      console.error("Error uploading file:", error);
                    }
                  }


                  surveyData[key] = uploadedUrls;
                }
              }
            }

            const responseData = {
              programId: programId,
              responses: surveyData,
              // initialEvaluationNote: (totalScore === undefined || maxScore === undefined)
              //   ? "non calculée"
              //   : `${totalScore}/${maxScore}`,
              user_id: userId,
              note:totalScore,
              totalNote: maxScore,
              action: "envoyer",
            };

            try {
              await sendSurveyResponse(token, responseData);
              toast.success("Vos réponses ont été envoyées avec succès!", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
              });

              setTimeout(() => {
                navigate("/laureat-dashboard");
              }, 2000);
            } catch (error) {
              console.error("Error sending final response:", error);
              toast.error("Erreur lors de l'envoi des réponses. Veuillez réessayer.", {
                position: "bottom-right",
                autoClose: 2000,
                theme: "colored",
                transition: Bounce,
              });
            }
          });

          setSteps(stepsTitles);
          setSurveyModel(survey);
        } else {
          setErrorData("No survey data found for this program.");
        }
      } catch (err) {
        setError("Failed to fetch the program to fill. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProgramToFill();
  }, [userId, navigate]);

  if (loading) return <CircularProgress size={24} />;

  if (errorData) {
    return (
      <div>
        <LaureatGetSurveyForm />
        {/* <p>{errorData ? errorData : "An error occurred, please try again."}</p> */}
      </div>
    );
  }

  return (
    <div className="App">
      {steps.length > 0 && (
        <Stepper
          steps={steps}
          currentStep={isFinalStep ? steps.length : currentStep}
        />
      )}
      {surveyModel ? (
        // isFinalStep ? (
        //   <div className="flex justify-center flex-col items-center">
        //     <h2 className="text-xl font-bold mb-4">Final Validation</h2>
        //     <button
        //       className="bg-[#0976BC] text-white rounded-[37px] py-3 px-9"
        //       onClick={() => surveyModel.completeLastPage()}
        //     >
        //       Submit
        //     </button>
        //   </div>
        // ) : (
        <Survey model={surveyModel} />
        // )
      )
        : (
          // <div>No survey available.</div>
          <LaureatGetSurveyForm />

        )}
      <ToastContainer />
    </div>
  );
};

export default LaureatGetProgramSurvey;
