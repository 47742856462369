import React from "react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import BannerImage from "../../assets/BannerAppels.svg";

const ContactBanner = () => {
  return (
    <div className="flex justify-center items-center w-[80%] mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative  pt-3">
      <img
        src={BannerImage}
        className="h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] object-cover absolute rounded-2xl "
        alt="Banner"
      />
      <div className="relative text-white gap-2 xl:mb-48 -mt-20 sm:-mt-16 xl:mt-28 ">
        <h2 className="text-center font-roboto-slab text-2xl sm:text-3xl xl:text-5xl">
          Contactez-nous
        </h2>
        <div className="flex sm:flex-row flex-col  items-center mt-4 gap-[2px] sm:gap-5">
          <p className="flex items-center gap-2">
            <EnvelopeIcon className="w-6 h-6 text-white" />
            <span className="text-lg">support@copa.transforme.cd</span>
          </p>
          <p className="flex items-center gap-2">
            <PhoneIcon className="w-6 h-6 text-white" />
            <span className="text-lg">(+243) 830 033 033</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
