import React, { useEffect, useState } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import SignDocs from "../Laureat-Dashboard/SignDocs";
import { laureatStatistics } from "../../services/statistics.service";
import { useAuth } from "../../contexts/AuthContext";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getAllProgramsFilters } from "../../services/program.service";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const DashboardStatistics = () => {

  const { userId } = useAuth();
  const [inProgressEvaluation, setInProgressEvaluation] = useState(0);
  const [submittedApplications, setSubmittedApplications] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [totalApplications, setTotalApplications] = useState(0)
  const [programs, setPrograms] = useState([]);
  console.log(programs, "programs");
  const [selectedProgram, setSelectedProgram] = useState({});
  const [pieChartData, setPieChartData] = useState({
    // percentageEnCoursEvaluation: 0,
    percentageSoumis: 0,
    percentageBrouillon: 0,
    // percentageEligible: 0,
  });
  console.log(inProgressEvaluation);
  console.log(submittedApplications);
  console.log(drafts);

  useEffect(() => {
    const fetchStatistics = async (programId = null) => {
      try {
        const response = await laureatStatistics(null, null, programId);
        console.log("response", response);
        setTotalApplications(response?.totalApplications)
        setInProgressEvaluation(response?.totalEnCoursEvaluation);
        setSubmittedApplications(response?.totalSoumis);
        setDrafts(response?.totalBrouillon);
        setPieChartData({
          // percentageEnCoursEvaluation: response?.percentageEnCoursEvaluation || 0,
          percentageSoumis: response?.percentageSoumis || 0,
          percentageBrouillon: response?.percentageBrouillon || 0,
          // percentageEligible: response?.percentageEligible || 0,
        });
      } catch (error) {
        console.error("Error fetching laureat statistics:", error.message);
      }
    };

    fetchStatistics(selectedProgram.id);
  }, [userId, selectedProgram]);

  const lineChartData = {
    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet"],
    datasets: [
      {
        label: "Applications reçues",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const handleProgramChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedProgram(selectedValue); // Update the selected program
  };

  useEffect(() => {
    const fetchProgramFilters = async () => {
      try {
        const programFilters = await getAllProgramsFilters();
        programFilters?.unshift({ id: undefined, title: "Appels à candidature" }); // Add default option
        setPrograms(programFilters);
      } catch (error) {
        console.log("error in fetching program filters list", error);
      }
    };

    fetchProgramFilters(); // Fetch programs on component mount
  }, [userId]);

  // Pie chart data for "percentageSoumis"
  const pieDataSoumis = {
    labels: ["Soumis", "Non-Soumis" ],
    datasets: [
      {
        data: [pieChartData.percentageSoumis, 100 - pieChartData.percentageSoumis],
        backgroundColor: [ "rgba(	34, 238, 15, 0.6)", "rgba(9, 118, 188, 0.6)"],
        borderColor: ["rgba(34, 238, 15, 1)",   "rgba(9, 118, 188, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // Pie chart data for "percentageBrouillon"
  const pieDataBrouillon = {
    labels: ["Brouillon", "Non-Brouillon" ],
    datasets: [
      {
        data: [pieChartData.percentageBrouillon, 100 - pieChartData.percentageBrouillon],
        backgroundColor: [  "rgba(255, 206, 86, 0.2)", "rgba(9, 118, 188, 0.6)"],
        borderColor: [ "rgba(255, 206, 86, 1)", "rgba(9, 118, 188, 1)"  ],
        borderWidth: 1,
      },
    ],
  };

  const barChartData = {
    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet"],
    datasets: [
      {
        label: "Coachs embauchés",
        data: [12, 19, 3, 5, 2, 3, 9],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">Tableau de bord</h2>
      <p className="text-gray-500 text-sm  mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="pb-4 flex justify-end">
        <FormControl variant="outlined" sx={{ width: 270 }}>
          <Select
            value={selectedProgram.title || ""}
            onChange={handleProgramChange} // Handle change
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => (
              <ChevronDownIcon className="w-7 h-7 text-white mr-3" />
            )}
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <i className="bi bi-filter  text-xl mx-2"></i>
                <span style={{ flex: 1, textAlign: "center" }}>
                  {selectedProgram?.title || "Appels à candidature"}
                </span>
              </div>
            )}
            sx={{
              backgroundColor: "#0976BC",
              color: "white",
              borderRadius: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "30px",
                paddingRight: "2.5rem",
              },
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
          >
            {programs.map((program) => (
              <MenuItem
                key={program.id}
                value={JSON.stringify({ id: program.id, title: program.title })}
              >
                {program.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {/* <SignDocs /> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        {/* Autres cartes statistiques */}
        <div className="bg-card px-4 py-3 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Total de candidatures reçues
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">{totalApplications}</p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures soumis
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">{submittedApplications}</p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures brouillon
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">{drafts}</p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            En cours d’évaluation
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">{inProgressEvaluation}</p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="mt-8 w-[48%] ">
          <h3 className="text-xl font-bold mb-4">Répartition des Candidatures Soumis</h3>
          <div className="bg-white p-6 rounded-lg shadow-md border border-slate-400">
            <Pie data={pieDataSoumis} />
          </div>
        </div>

        <div className="mt-8  w-[48%]">
          <h3 className="text-xl font-bold mb-4">Répartition des Candidatures Brouillon</h3>
          <div className="bg-white p-6 rounded-lg shadow-md border border-slate-400">
            <Pie data={pieDataBrouillon} />
          </div>
        </div>
      </div>
      {/* <div className="mt-3 bg-card p-4 rounded-lg shadow-md border border-slate-400 bg-white">
        <h2 className="text-lg font-semibold text-foreground">Statistiques</h2>
        <div className="mt-4">
          <Line data={lineChartData} />
        </div>
        <div className="mt-8">
          <Bar data={barChartData} />
        </div>
        <div className="flex justify-between mt-4">
          <span className="text-muted-foreground">Applications reçues</span>
          <span className="text-muted-foreground">Août 2024</span>
          <button className="bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded p-2">
            ...
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default DashboardStatistics;
