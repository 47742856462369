import axios from "axios";
import { json } from "react-router-dom";
import axiosInstance from "../helpers/axiosIntercepter";

export const createForm = async (formData) => {
  try {
    const response = await axiosInstance.post(
      "/forms",
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error creating form", error);
    throw error;
  }
};

export const getAllForms = async (queryParams) => {


  const params = new URLSearchParams();

  if (queryParams?.page) params.append('page', queryParams?.page);
  if (queryParams?.per_page) params.append('per_page', queryParams?.per_page);
  if (queryParams?.status) params.append('status', queryParams?.status);
  if (queryParams?.searchTerm) params.append('title', queryParams?.searchTerm);
  // if (startCreatedAt) params.append('startCreatedAt', startCreatedAt);
  // if (endCreatedAt) params.append('endCreatedAt', endCreatedAt);

  try {
    const response = await axiosInstance.get(
      "/forms",
      {
        params,
        headers: {
          'Accept': 'application/json',
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching all forms:", error);
    throw error;
  }
};


export const linkFormToProgram = async ({ formId, programId }) => {
  try {
      const response = await axiosInstance.post(
          `/forms/link-from-to-program/${formId}/${programId}`,
         
      );
      return response.data;
  } catch (error) {
      console.error("error linking form to program", error);
      throw error;
  }
};

export const getFormById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/forms/${id}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching all forms:", error);
    throw error;
  }
};

export const updateForm = async (formId, jsonData) => {
  console.log(jsonData, "#check here if stringified or not#")
    try {
      const response = await axiosInstance.put(
          `/forms/${formId}`,jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        
      );
      console.log("resp update/%%%%%", response.data, response)
      return response.data;
  } catch (error) {
      console.error("error updating form", error);
      throw error;
  }
}