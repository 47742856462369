import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSurveyResponsesByUserId } from "../../../services/surveyResponse.service";
import SignDocs from "../SignDocs";
import { useAuth } from "../../../contexts/AuthContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import DocsSigné from "../DocsSigné";
import { laureatStatistics } from "../../../services/statistics.service";
import GetStatusLaureat from "../../../helpers/getStatusLaureat";

const LaureatStatistics = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalApplications, setTotalApplications] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [openSignDocs, setOpenSignDocs] = useState(false);
  const [openDocsSigné, setOpenDocsSigné] = useState(false);
  const [inProgressEvaluation, setInProgressEvaluation] = useState(0);
  const [submittedApplications, setSubmittedApplications] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [pdfUrl, setPdfUrl] = useState("");
  const { userId } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSurveyResponses = async () => {
      try {
        const queryParams = { page: page + 1, limit: rowsPerPage };
        const response = await getSurveyResponsesByUserId(userId, queryParams);
        setApplications(response.surveyResponses);
        setFilteredApplications(response.surveyResponses);
        setTotalApplications(response.total);
      } catch (error) {
        console.error("Error fetching survey responses:", error.message);
      }
    };

    fetchSurveyResponses();
  }, [userId, page, rowsPerPage]);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await laureatStatistics(userId, null);

        setInProgressEvaluation(response.totalEnCoursEvaluation);
        setSubmittedApplications(response.totalSoumis);
        setDrafts(response.totalBrouillon);
      } catch (error) {
        console.error("Error fetching laureat statistics:", error.message);
      }
    };

    fetchStatistics();
  }, [userId]);

  useEffect(() => {
    const filterApplications = () => {
      let filtered = applications;

      if (searchQuery) {
        const lowerCaseQuery = searchQuery.toLowerCase();
        filtered = filtered.filter((app) => {
          const matchesTitle = app.program.title
            .toLowerCase()
            .includes(lowerCaseQuery);
          const matchesId = app.id.toString().includes(lowerCaseQuery);
          const matchesDate = formatDate(app.createdAt)
            .toLowerCase()
            .includes(lowerCaseQuery);
          return matchesTitle || matchesId || matchesDate;
        });
      }

      if (statusFilter) {
        filtered = filtered.filter((app) => app.status === statusFilter);
      }

      setFilteredApplications(filtered);
    };

    filterApplications();
  }, [searchQuery, statusFilter, applications]);

  const handleCheckboxChange = (applicationId) => {
    setSelectedApplications((prevSelected) =>
      prevSelected.includes(applicationId)
        ? prevSelected.filter((id) => id !== applicationId)
        : [...prevSelected, applicationId]
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleOpenDocsSigné = (url) => {
    setPdfUrl(url);
    setOpenDocsSigné(true);
  };

  const handleDetailsClick = (app) => {
    if (["SOUMIS", "Rejeté", "À Évaluer", "Éligible"].includes(app?.status)) {
      navigate(`/laureat-dashboard/details/${app?.id}`);
    } else {
      localStorage.setItem("programId", app?.program?.id);
      navigate("/laureat-dashboard/fill-form");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">Dashboard</h2>
      <p className="text-gray-500 text-sm mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        <div className="bg-card px-4 py-3 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Total des applications
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {totalApplications}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>

        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures soumis
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {submittedApplications}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Candidatures Brouillon
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">{drafts}</p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            En cours d’évaluation
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">
            {inProgressEvaluation}
          </p>
          <hr />
          <select className="mt-2 bg-input text-muted-foreground rounded">
            <option>Ce mois</option>
          </select>
        </div>
      </div>

      <div className="border rounded-lg p-3 bg-white mt-4">
        <div className="flex flex-wrap gap-2 items-center justify-end mb-3">
          <div className="flex-grow w-full md:w-auto">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="search"
                id="default-search"
                value={searchQuery}
                onChange={handleSearchInputChange}
                className="py-[12px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none min-w-80"
                placeholder="Rechercher par nom, date, rôle,..."
                required
              />
            </div>
          </div>

          <div className="flex items-center">
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className="py-2 px-4 border border-gray-300 rounded-md text-gray-600"
            >
              <option value="">Tous les statuts</option>
              {/* <option value="Éligible">Éligible</option> */}
              <option value="SOUMIS">Soumis</option>
              <option value="Brouillon">Brouillon</option>
              {/* <option value="Rejeté">Rejeté</option>
              <option value="À Évaluer">À Évaluer</option> */}
            </select>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedApplications.length > 0 &&
                      selectedApplications.length < applications?.length
                    }
                    checked={
                      selectedApplications.length > 0 &&
                      selectedApplications.length === applications?.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedApplications(
                          applications?.map((app) => app.id)
                        );
                      } else {
                        setSelectedApplications([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Ref ID</TableCell>
                <TableCell>Appel à candidature</TableCell>
                <TableCell>Date d'application</TableCell>
                <TableCell>Date de réponse</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Convention</TableCell>
                <TableCell>Formulaire juridique</TableCell>
                <TableCell>Détails</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredApplications?.map((app) => (
                <TableRow
                  key={app.id}
                  hover
                  selected={selectedApplications.includes(app.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedApplications.includes(app.id)}
                      onChange={() => handleCheckboxChange(app.id)}
                    />
                  </TableCell>
                  <TableCell>{`${app.id}`}</TableCell>
                  <TableCell>{app.program.title}</TableCell>
                  <TableCell>{formatDate(app.createdAt)}</TableCell>
                  <TableCell>{formatDate(app.updatedAt)}</TableCell>
                  <TableCell>
                    <GetStatusLaureat status={app.status} />
                  </TableCell>
                  <TableCell>
                    {app?.conventionStatus === "SIGNÉ" &&
                    app?.conventionDetails?.url ? (
                      <span
                        style={{
                          color: "green",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        className="flex"
                        onClick={() =>
                          handleOpenDocsSigné(app?.conventionDetails?.url)
                        }
                      >
                        Convention <br /> signée
                      </span>
                    ) : app?.conventionStatus === "NON SIGNÉ" ? (
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        className=" flex p-auto"
                        onClick={() => setOpenSignDocs(true)}
                      >
                        Convention <br /> non signée
                      </span>
                    ) : (
                      <span style={{ color: "black" }} className=" flex">
                        ---
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {app?.juridiqueStatus === "SENT" ? (
                      <Button
                        className="bg-[#0976BC] hover:bg-[#065A94] transition-colors duration-300 text-white px-[12px] py-[5px] rounded-full text-xs text-inherit normal-case font-normal flex"
                        variant="contained"
                        disableElevation
                        onClick={() =>
                          navigate(`/laureat-dashboard/formJuridique/${app.id}`)
                        }
                      >
                        Justificatif
                      </Button>
                    ) : app?.juridiqueStatus === "UPLOADED" ? (
                      <span style={{ color: "green" }} className="flex">
                        Justificatif <br /> envoyé
                      </span>
                    ) : (
                      <span style={{ color: "black" }} className="flex">
                        ---
                      </span>
                    )}
                  </TableCell>

                  <TableCell>
                    <span
                      className="text-[#FF0000] underline text-sm font-medium cursor-pointer"
                      onClick={() => handleDetailsClick(app)}
                    >
                      Détails <span>↗</span>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalApplications}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      {openSignDocs && <SignDocs onClose={() => setOpenSignDocs(false)} />}
      {openDocsSigné && (
        <DocsSigné pdfUrl={pdfUrl} onClose={() => setOpenDocsSigné(false)} />
      )}
    </div>
  );
};

export default LaureatStatistics;
