import { Form, Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import InputField from '../common/InputField';
import * as Yup from 'yup';
import { forgetPasswordService } from '../../services/auth.service';
import { Bounce, toast, ToastContainer } from "react-toastify";
import logo from "../../assets/logo.png"

const ForgetPasswordForm = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Adresse e-mail invalide')
      .required('Adresse e-mail est requise'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await forgetPasswordService(values);
      toast.success('Instructions de réinitialisation envoyées à votre adresse e-mail', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        onClose: () => navigate("/auth/login"), // Redirect after toast is closed
      });
    } catch (error) {
      toast.error("Erreur lors de l'envoi des instructions de réinitialisation", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center mb-4">
        <Link
          to={`/`}
          className=" flex justify-center "
        >
          <img
            src={logo}
            alt="Logo"
            className="w-50"
          />
        </Link>
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xl">
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-4xl font-bold text-[#0976BC]">
            Réinitialiser mot de passe
          </h1>
          <p className="text-center text-[#3D3D3D] text-sm mt-2">
            Entrez votre adresse e-mail pour recevoir les instructions de
            réinitialisation.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-2">
              <InputField type="email" name="email" label="Adresse e-mail *" placeholder="Entrer votre adresse e-mail" />
              <div>
                <button
                  className="w-full bg-[#0976BC] hover:bg-[#065A94] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-2"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="mt-6 text-center">
          <p className="text-gray-600 text-sm">
            Retour à la{" "}
            <Link
              to="/auth/login"
              className="text-[#0976BC] hover:text-[#065A94] font-semibold"
            >
              Connexion
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgetPasswordForm;
