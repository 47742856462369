import React, { useEffect, useState } from 'react'
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-core/i18n/french";
import { PlainLight } from "survey-core/themes";
import { useNavigate, useParams } from "react-router-dom";
import {getFormById} from "../../../../services/form.service"
import { CircularProgress } from '@mui/material';

const DetailsFormulaireUnite = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [surveyModel, setSurveyModel] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate()
  
    const fetchFormDetails = async () => {
      setLoading(true);
      try {
        const data = await getFormById(id);
        console.log('form by id', data)
        setData(data);
        if(data){
        const form = data?.fields
        const parsedJson = JSON.parse(form)
        const survey = new Model(parsedJson)
        survey.mode = "display"
        survey.applyTheme(PlainLight);
        survey.locale = "fr";
        setSurveyModel(survey)
      }
      } catch (error) {
        console.error("Error fetching survey responses:", error);
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      fetchFormDetails();
    }, []);
  return (
    <>
        {loading ? <div className="text-center p-4"><CircularProgress /></div> : 
        (<div>
       
        <div className="mx-auto px-6 py-4 bg-card text-foreground bg-slate-50">
            <div className='flex justify-between p-2'>
              <h5>Détails formulaire ID: {id} </h5>
              <button 
                onClick={() => navigate('update')} 
                className={`px-4 py-2 mb-2 rounded-lg  ${data?.status === "Publié" ? 'bg-gray-100 text-gray-300' : 'bg-gray-200 text-gray-700  hover:bg-gray-300'}`} 
                disabled={data?.status === "Publié"}
            >
              Modifier
            </button>
            </div>
            <div>
                {surveyModel && <Survey model={surveyModel} />} 
            </div>
        </div>
        <div className='flex justify-start p-2 m-2'>
        <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la page d'accueil
            </button>
        </div>
        </div>
      )}
    </>
  )
}

export default DetailsFormulaireUnite
