import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import config from "./assets/environments/config.json"

import "./index.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {QueryClientProvider, QueryClient} from "@tanstack/react-query"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import reportWebVitals from "./reportWebVitals";
import router from "./setup/routes";
import ActiveMenuContextProvider from "./contexts/MenuContext";
import AuthContextProvider from "./contexts/AuthContext";

function observeAndRemoveBanner() {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        const banner = document.querySelector(".svc-creator__banner");
        if (banner) {
          banner.remove();
        }
      }
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });

  const banner = document.querySelector(".svc-creator__banner");
  if (banner) {
    banner.remove();
  }
}

// Call the function to start observing the DOM
observeAndRemoveBanner();

const queryClient = new QueryClient({})

console.log("REACT_APP_PUBLIC_NEST_BASE_URL", process.env.REACT_APP_PUBLIC_NEST_BASE_URL);
console.log("NEST_BASE_URL", process.env.NEST_BASE_URL);
console.log("process.env", process.env);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <ActiveMenuContextProvider>
        <GoogleReCaptchaProvider reCaptchaKey={config.KEY}>
        <RouterProvider router={router} />
        </GoogleReCaptchaProvider>
      </ActiveMenuContextProvider>
    </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
