import React from "react";
import { Line, Bar } from "react-chartjs-2";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const CoachStatistics = () => {
  const lineChartData = {
    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet"],
    datasets: [
      {
        label: "Applications reçues",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const barChartData = {
    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet"],
    datasets: [
      {
        label: "Coachs embauchés",
        data: [12, 19, 3, 5, 2, 3, 9],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  return (
    <div className="mx-auto p-6 bg-card text-foreground  mb-5 bg-slate-50 h-full">
      <h2 className="text-3xl font-bold mb-0">Tableau de bord</h2>
      <p className="text-gray-500 text-sm  mb-2">
        Suivez et gérez les informations et les activités des clients.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        {/* Autres cartes statistiques */}
        <div className="bg-card px-4 py-3 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Total des applications
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">03</p>
          <hr />
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            En cours d’évaluation
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">01</p>
          <hr />
        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Appels à candidature soumis
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">12</p>
          <hr />

        </div>
        <div className="bg-card p-4 rounded-lg shadow-md border border-slate-400 transition duration-300 hover:scale-105 bg-white">
          <h2 className="text-base font-normal text-gray-700 flex items-center gap-3">
            Brouillons
            <InformationCircleIcon className="w-5 h-5" />
          </h2>
          <p className="text-2xl font-bold text-[#0976BC]">10</p>
          <hr />
        </div>
      </div>
      <div className="mt-3 bg-card p-4 rounded-lg shadow-md border border-slate-400 bg-white">
        <h2 className="text-lg font-semibold text-foreground">Statistiques</h2>
        <div className="mt-4">
          <Line data={lineChartData} />
        </div>
        <div className="mt-8">
          <Bar data={barChartData} />
        </div>
        <div className="flex justify-between mt-4">
          <span className="text-muted-foreground">Applications reçues</span>
          <span className="text-muted-foreground">Août 2024</span>
          <button className="bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded p-2">
            ...
          </button>
        </div>
      </div>
    </div>
  );
}

export default CoachStatistics


