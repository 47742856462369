import bgPropos from "../../assets/images/Banner-about.jpg";

const Banner = () => {
  return (
    <div className="flex justify-center items-center w-[80%] mx-auto flex-col h-[40vh] sm:h-[30vh] lg:h-[30vh] xl:h-[45vh] 2xl:h-[40vh] relative">
      <img
        src={bgPropos}
        className=" absolute inset-0 w-full h-full object-cover rounded-2xl"
      />
      <div className="relative  text-white flex flex-col justify-center items-center gap-2">
        <h2 className=" xl:w-3/4 text-center font-roboto-slab text-4xl sm:text-5xl">
          À propos
        </h2>
        <p className="text-center w-[70%] sm:w-[60%]">
        Avec COPA Transforme, donnez à votre projet les moyens de réussir grâce à un accompagnement sur mesure et des ressources adaptées à chaque étape de votre croissance.
        </p>
      </div>
      <div>
        <input className="" />
      </div>
    </div>
  );
};

export default Banner;
