import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlainLight } from "survey-core/themes";
import { Model, Survey } from "survey-react-ui";
import { getSurveyResponseById } from "../../../../services/surveyResponse.service";
import axiosInstance from "../../../../helpers/axiosIntercepter";

const DetailsCandidatures = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  console.log(data, "data");
  const [surveyModel, setSurveyModel] = useState(null);
  const [surveyModel2, setSurveyModel2] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const amzDate = urlParams.get("X-Amz-Date");
    const amzExpires = urlParams.get("X-Amz-Expires");

    if (amzDate && amzExpires) {
      const expirationDate = new Date(
        amzDate.slice(0, 4) +
          "-" + // Year
          amzDate.slice(4, 6) +
          "-" + // Month
          amzDate.slice(6, 8) +
          "T" + // Day
          amzDate.slice(9, 11) +
          ":" + // Hour
          amzDate.slice(11, 13) +
          ":" + // Minute
          amzDate.slice(13, 15) +
          "Z" // Second
      );

      const expiresInMs = parseInt(amzExpires, 10) * 1000;
      const expirationTime = expirationDate.getTime() + expiresInMs;

      console.log("Expiration Date:", expirationDate);
      console.log("Expires In (ms):", expiresInMs);
      console.log("Expiration Time:", new Date(expirationTime));

      return Date.now() > expirationTime;
    }

    return false;
  };

  const fetchUpdatedUrl = async (fileName) => {
    try {
      const response = await axiosInstance.get(
        `/upload/url?fileName=${fileName}&strategy=minio&visibility=private&expirationTime=180`
      );
      return response.data;
    } catch (error) {
      console.log("error in my update expired url", error);
    }
  };
  const updatedFilesDate = async (questions) => {
    const updatedObject = { ...questions };

    for (const [key, value] of Object.entries(updatedObject)) {
      if (Array.isArray(value) && value[0]?.content) {
        const file = value[0];
        const isExpired = isUrlExpired(file.content);
        if (isExpired) {
          const newUrl = await fetchUpdatedUrl(file.name);
          updatedObject[key] = [{ ...file, content: newUrl?.url }];
        }
      }
    }

    return updatedObject;
  };

  useEffect(() => {
    const fetchSurveyResponse = async () => {
      try {
        const responseData = await getSurveyResponseById(id);

        if (responseData) {
          const parsedJson = JSON.parse(
            responseData?.surveyResponse?.program?.form?.fields
          );
          const survey = new Model(parsedJson);
          setSurveyModel(survey);
          survey.data = responseData.surveyResponse.responses;
          survey.mode = "display";
          survey.applyTheme(PlainLight);
          survey.locale = "fr";
          const formJuridique = responseData?.surveyResponse?.juridiqueForm;

          if (formJuridique) {
            const parsedJson = JSON.parse(formJuridique?.fildes);
            const survey = new Model(parsedJson);
            const res = await updatedFilesDate(
              responseData?.surveyResponse?.juridiqueFormResponses
            );
            survey.data = res;
            survey.mode = "display";
            survey.applyTheme(PlainLight);
            survey.locale = "fr";
            setSurveyModel2(survey);
          }
        }
        setData(responseData);
      } catch (error) {
        console.error("Error fetching survey response:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyResponse();
  }, [id, loading]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!data) {
    return <div>Data not found</div>;
  }

  return (
    <div className="mx-auto p-6 bg-card text-foreground mb-5 bg-slate-50 h-full">
      <h1 className="text-3xl font-bold mb-0">Détails de candidature</h1>
      <div className="border rounded-lg p-4 mt-3 bg-white ">
        <div className="space-y-3">
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Status :</span>{" "}
            <div
              className={`inline-flex items-center justify-center px-[12px] py-[5px] rounded-full text-xs font-medium min-w-[90px]  ${
                data?.surveyResponse?.status === "SOUMIS"
                  ? "text-green-800 bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Brouillon"
                  ? "text-yellow-800 bg-yellow-100 border border-yellow-900"
                  : data?.surveyResponse?.status === "Éligible"
                  ? "text-black bg-green-100 border border-green-900"
                  : data?.surveyResponse?.status === "Rejeté"
                  ? "text-red-800 bg-red-100 border border-red-900"
                  : "text-blue-800 bg-blue-100 border border-blue-900"
              }`}
            >
              {data?.surveyResponse?.status || "N/A"}
            </div>
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950 text-lg">Nom et prénom :</span>{" "}
            {`${data?.userData?.firstName} ${data?.userData?.lastName}` ||
              "N/A"}
          </div>

          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">Adresse E-mail :</span>{" "}
            {data?.userData?.email || "N/A"}
          </div>
          <hr />
          <div className="text-gray-700 text-base">
            <span className="text-gray-950  text-lg">
              Description du projet :
            </span>{" "}
            <div dangerouslySetInnerHTML={{__html: data?.surveyResponse?.program?.description || "N/A"}}/>
          </div>
          <hr />
          {data.file && (
            <div className="mt-6 bg-gray-100 border border-gray-300 rounded-lg p-4 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="/path-to-your-pdf-icon"
                  alt="PDF Icon"
                  className="w-8 h-8 mr-4"
                />
                <div>
                  <p className="text-sm font-semibold">
                    {data.file.name || "File.pdf"}
                  </p>
                  <p className="text-xs text-gray-500">
                    {data.file.size || "File size"} - Completed
                  </p>
                </div>
              </div>
              <button className="text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          {surveyModel && <Survey model={surveyModel} />}
          {surveyModel2 && (
            <div className="mx-auto p-6 bg-card text-foreground bg-slate-50">
              <Survey model={surveyModel2} />
            </div>
          )}
          <div className="mt-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              Retour à la liste des candidatures
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCandidatures;
