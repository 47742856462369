import React from 'react'
import ContactForm from '../../components/forms/ContactForm'
import ContactBanner from './ContactBanner'

const Contact = () => {
  return (
    <div className='relative'>
        <ContactBanner/>
        <div className='-mt-28 mb-5 '>
            <ContactForm/> 
        </div>
    </div>
  )
}

export default Contact
