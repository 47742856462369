import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  Typography,
  CircularProgress,
} from '@mui/material';
import SuccessModal from './SuccessModal';
import { sendInvitation } from '../../../services/user.service';


const initialFieldState = { value: '', error: '' };

const InviteCoachDialog = ({ open, onClose, groups }) => {
  const [fields, setFields] = useState({
    nom: initialFieldState,
    prenom: initialFieldState,
    email: initialFieldState,
    role: initialFieldState,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); 
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFieldChange = (field) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [field]: { value, error: value ? '' : prevFields[field].error },
    }));
  };

  const handleSendInvitation = async () => {
    let hasErrors = false;
    const newFields = {
      nom: { ...fields.nom, error: '' },
      prenom: { ...fields.prenom, error: '' },
      email: { ...fields.email, error: '' },
      role: { ...fields.role, error: '' },
    };

    console.log(newFields, "newFields")

    if (!newFields.nom.value) {
      newFields.nom.error = 'Le nom est requis';
      hasErrors = true;
    }
    if (!newFields.prenom.value) {
      newFields.prenom.error = 'Le prénom est requis';
      hasErrors = true;
    }
    if (!newFields.email.value) {
      newFields.email.error = "L'adresse e-mail est requise";
      hasErrors = true;
    }
    if (!newFields.role.value) {
      newFields.role.error = 'Le rôle est requis';
      hasErrors = true;
    }

    if (hasErrors) {
      setFields(newFields);
    } else {
      setLoading(true);
      setError('');

      const invitationData = {
        first_name: newFields.nom.value,
        last_name: newFields.prenom.value,
        email: newFields.email.value,
        userGroup: newFields.role.value,
      };

      console.log(invitationData, "invitationData%%%%%%%%")

      try {
        await sendInvitation(invitationData);
        setShowSuccessModal(true);
        setTimeout(() => {
          onClose(true);
        }, 100);
      } catch (err) {
        setError(err.message); 
      } finally {
        setLoading(false); 
      }
    }
  };

  const handleCloseSuccessModal = () => {
    setFields({
      nom: initialFieldState,
      prenom: initialFieldState,
      email: initialFieldState,
      role: initialFieldState,
    });
    setShowSuccessModal(false);
  };

  const renderTextField = (label, field) => (
    <div>
      <Typography variant="body2" sx={labelStyle}>
        {label}
      </Typography>
      <TextField
        placeholder={label}
        variant="outlined"
        fullWidth
        value={fields[field].value}
        onChange={handleFieldChange(field)}
        error={!!fields[field].error}
        helperText={fields[field].error}
        InputProps={{ sx: inputStyle }}
        sx={textFieldStyle}
      />
    </div>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <div style={containerStyle}>
          <DialogTitle sx={titleStyle}>Inviter un nouvel utilisateur</DialogTitle>
          <DialogContent>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
              </Box>
            ) : (
              <Box component="form" sx={formStyle}>
                {renderTextField('Nom', 'nom')}
                {renderTextField('Prénom', 'prenom')}
                {renderTextField('Adresse E-mail', 'email')}
                <FormControl fullWidth error={!!fields.role.error}>
                  <Typography variant="body2" sx={labelStyle}>
                    Rôle
                  </Typography>
                  <Select
                    value={fields.role.value}
                    onChange={handleFieldChange('role')}
                    displayEmpty
                    renderValue={fields.role.value ? undefined : () => 'Sélectionner un rôle'}
                    sx={selectStyle}
                  >
                    <MenuItem disabled value="">
                      Sélectionner un rôle
                    </MenuItem>
                    {groups
                      ?.filter(
                        (group) =>
                          group.name.toLowerCase() !== 'admins' &&
                          group.name.toLowerCase() !== 'laureats'
                      )
                      ?.map((group) => (
                        <MenuItem key={group.id} value={group.name}>
                          {group.name.charAt(0).toUpperCase() + group.name.slice(1)}
                        </MenuItem>
                      ))}
                  </Select>
                  {fields.role.error && (
                    <Typography variant="body2" color="error">
                      {fields.role.error}
                    </Typography>
                  )}
                </FormControl>
                {error && (
                  <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                    {error}
                  </Typography>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={actionsStyle}>
            <Button onClick={onClose} variant="outlined" sx={buttonStyle}>
              Annuler
            </Button>
            <Button
              onClick={handleSendInvitation}
              variant="outlined"
              sx={buttonStyle}
              disabled={loading} 
            >
              Envoyer l'invitation
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <SuccessModal open={showSuccessModal} onClose={handleCloseSuccessModal} />
    </>
  );
};



const buttonStyle = {
  color: "#0976BC",
  borderColor: "#0976BC",
  borderWidth: "1px",
  backgroundColor: "#fff",
  borderRadius: "25px",
  padding: "4px 20px",
  textTransform: "inherit",
  "&:hover": { backgroundColor: "#0976BC", color: "#fff" },
};

const containerStyle = {
  paddingBottom: "16px",
  margin: "30px",
  backgroundColor: "#f8fafc",
  borderRadius: "8px",
  border: "1px solid #f0f0f0",
};

const titleStyle = {
  textAlign: "center",
  color: "#0976BC",
  marginTop: "10px",
  fontSize: "22px",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  marginTop: "16px",
  marginBottom: "16px",
};

const labelStyle = {
  marginBottom: "3px",
  fontSize: "16px",
};

const inputStyle = {
  height: "45px",
  borderRadius: "6px",
  backgroundColor: "#fff",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ccc" },
    "&:hover fieldset": { borderColor: "#ccc" },
    "&.Mui-focused fieldset": { borderColor: "#ccc" },
  },
};

const selectStyle = {
  height: "45px",
  borderRadius: "6px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
  "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
};

const actionsStyle = {
  justifyContent: "center",
  gap: "2px",
  justifyContent: "right",
  marginRight: "20px",
};

export default InviteCoachDialog;
