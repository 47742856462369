import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../common/InputField";
import axiosInstance from "../../helpers/axiosIntercepter";
import Select from "react-select";
import mailIcon from "../../assets/Mail.svg";
import logo from "../../assets/logo.png";
import { getVilles } from "../../services/ville.service";

const RegisterForm = () => {
  const [confModal, setConfModal] = useState(false);
  const [errModal, setErrModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [villesOptions, setVillesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    ville: "",
    post_name: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Nom est requis"),
    last_name: Yup.string().required("Prénom est requis"),
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("Adresse e-mail est requis"),
    ville: Yup.object().nullable().required("Sélection est requise"),
  });

  const fetchVilles = async (searchTerm = "") => {
    setIsLoading(true);
    try {
      const { data } = await getVilles(searchTerm);
      const options = data.map((ville) => ({
        value: ville.id,
        label: ville.name,
      }));
      setVillesOptions(options);
    } catch (error) {
      console.error("Error fetching villes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVilles(searchTerm);
  }, [searchTerm]);

  const onSubmit = async (values) => {
    try {
      const dataToSend = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email: values?.email,
        post_name: values?.post_name,
        ville_id: values?.ville.value,
      }

      const response = await axiosInstance.post(
        "/authentication/create",
        dataToSend
      );
      if (response.status === 201) {
        setConfModal(true);
      }
    } catch (error) {
      console.log(error);
      setErrModal(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center mb-4">
        <Link
          to={`/`}
          className=" flex justify-center "
        >
          <img src={logo} alt="Logo" className="w-50" />
        </Link>
      </div>

      {confModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">
            <img src={mailIcon} alt="" width={100} height={100} />
            <h3>Votre compte a été créé avec succès !</h3>
            <p className="text-center text-[#151269]">
              Veuillez vérifier votre e-mail pour finaliser la création de votre
              mot de passe.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={() => setConfModal(false)}
            >
              <Link to="/auth/login" className="text-white no-underline">
                Se connecter
              </Link>
            </button>
          </div>
        </div>
      )}

      {errModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/60">
          <div className="p-[4%] bg-white rounded-md flex flex-col gap-3 justify-center items-center">
            <h3>L'utilisateur existe déjà</h3>
            <p className="text-center text-[#151269]">
              Veuillez essayer avec un autre nom utilisateur ou email.
            </p>
            <button
              className="bg-[#0976BC] rounded-[37px] text-white p-2 py-3 min-w-[30%]"
              onClick={() => setErrModal(false)}
            >
              <Link to="/auth/register" className="text-white no-underline">
                Ressayer
              </Link>
            </button>
          </div>
        </div>
      )}

      <div className="bg-white p-10 rounded-lg shadow-lg w-[95%] max-w-2xl">
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-4xl font-bold text-[#0976BC]">Bienvenue</h1>
          <p className="text-center text-[#3D3D3D] text-sm mt-2">
            Entrez vos identifiants pour créer votre compte
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({ setFieldValue }) => (
            <Form className="flex flex-col gap-2">
              <InputField
                type="text"
                placeholder="Entrer votre nom"
                name="first_name"
                label="Nom *"
              />

              <InputField
                type="text"
                placeholder="Entrer votre post nom"
                name="post_name"
                label="Post nom"
              />
              <InputField
                type="text"
                placeholder="Entrer votre prénom"
                name="last_name"
                label="Prénom *"
              />
              <InputField
                type="email"
                name="email"
                label="Adresse e-mail *"
                placeholder="Entrer votre adresse e-mail"
              />

              <div className="mb-4 ">
                <label className="font-semibold">
                  Ville *
                </label>
                <Select
                  className="rounded-[10px]  border  w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[50px]"
                  name="select_option"
                  options={villesOptions}
                  isLoading={isLoading}
                  placeholder="Choisir..."
                  onInputChange={(inputValue) => setSearchTerm(inputValue)}
                  onChange={(option) => setFieldValue("ville", option)}
                />
              </div>

              <div className="mb-6">
                <p className="text-gray-600 text-xs">
                  En créant un compte, vous acceptez les{" "}
                  <a
                    href="/terms"
                    className="text-[#0976BC] hover:text-[#065A94] font-semibold"
                  >
                    Conditions d'utilisation
                  </a>{" "}
                  et la{" "}
                  <a
                    href="/privacy"
                    className="text-[#0976BC] hover:text-[#065A94] font-semibold"
                  >
                    Politique de confidentialité.
                  </a>
                </p>
              </div>
              <div>
                <button
                  className="w-full bg-[#0976BC] hover:bg-[#065A94] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  S’inscrire
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="mt-6 text-center">
          <p className="text-gray-600 text-sm">
            Déjà un compte?{" "}
            <Link
              to="/auth/login"
              className="text-[#0976BC] hover:text-[#065A94] font-semibold"
            >
              Connectez-vous
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
