import axios from "axios";
import axiosInstance from "../helpers/axiosIntercepter";

export const uploadFile = async (
  file,
  subDirectory,
  expirationTime = 18000,
  visibility = "public"
) => {
  const formData = new FormData();
  formData.append("strategy", "minio");
  formData.append("subDirectory", subDirectory);
  formData.append("expirationTime", expirationTime.toString());
  formData.append("files", file);
  formData.append("visibility", visibility);

  try {
    const response = await axiosInstance.post(
      "/upload",
      formData
    );
    return response?.data?.urls;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed. Please try again.");
  }
};
